[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .container {
    border: 0.5px solid #28a745 !important;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .container {
    border: 0.5px solid #dc3545 !important;
}
[data-ez-module-formvalidator] .form_item .feedback.invalid{
    display: none;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .feedback.invalid {
    display: block;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid] .feedback.invalid {
    color: #dc3545;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .icon.valid-status:before {
    content: "";
    color: #dc3545;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .icon.valid-status:before {
    content: "";
    color: #28a745;
}