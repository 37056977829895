[#{$open-attribute}="true"]{
	display: block;
}

[#{$open-attribute}="false"]{
	display: none;
}

[#{$open-attribute}="true"][#{$base-attribute-key}animation="slide-down"]{
	@include play-animation ( slide-down, 1s );
}

[#{$open-attribute}="true"][#{$base-attribute-key}animation="fade-in"]{
	@include play-animation ( fade-in, 1s );
}

[#{$open-attribute}="false"][#{$base-attribute-key}animation="slide-up"]{
	@include play-animation ( slide-up, 1s );
	display: block;
}

[#{$open-attribute}="false"][#{$base-attribute-key}animation="fade-out"]{
	@include play-animation ( fade-out, 1s );
	display: block;
}

button:focus{
	outline: 0;
}

[#{$base-attribute-key}overlay]{
	position: fixed;

	width: 100%;
	height: 100%;

	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background-color: black;

	z-index: 1000000;
}

[#{$base-attribute-key}overlay]:hover{
	cursor: pointer;
}

[#{$base-attribute-key}overlay][#{$open-attribute}="true"]{
	opacity: 0.6;
}

[#{$base-attribute-key}overlay][#{$open-attribute}="false"]{
	opacity: 0;
	z-index: -1;
}