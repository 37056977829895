
[data-ez-module-passwordchecker][data-ez-repeated-chars=true] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length=true] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length=true] .helper .helper-item.max-length {
    color: #28a745;
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=true] .icon.valid-status:before {
    color: #28a745;
}
[data-ez-module-passwordchecker] .helper .helper-item .icon.valid-status:before {
    content: "";
    color: #dc3545;
}
[data-ez-module-passwordchecker][data-ez-repeated-chars=false] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length=false] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length=false] .helper .helper-item.max-length {
    color: #dc3545;
}
[data-ez-module-passwordchecker][data-ez-repeated-chars=true] .helper .helper-item.repeated-chars .icon.valid-status:before,
[data-ez-module-passwordchecker][data-ez-max-length=true] .helper .helper-item.max-length .icon.valid-status:before,
[data-ez-module-passwordchecker][data-ez-min-length=true] .helper .helper-item.min-length .icon.valid-status:before {
    color: #28a745;
    content: "";
}
[data-ez-module-formvalidator] .form_item[data-ez-valid=false] .icon.valid-status:before {
    content: "";
    color: #dc3545;
}
[data-ez-module-passwordchecker] input[data-ez-input]:focus + .helper {
    display: block;
}