$base-attribute-key: data-ez-;

$module-attribute: #{$base-attribute-key}module-;

$item-attribute: #{$base-attribute-key}item;
$param-attribute: #{$base-attribute-key}param;

$open-attribute: #{$base-attribute-key}open;

$input-attribute: #{$base-attribute-key}input;
$output-attribute: #{$base-attribute-key}output;

$module-lightbox-attribute: #{$module-attribute}lightbox;
$module-form-validator-attribute: #{$module-attribute}formvalidator;
$module-cookie-directive-attribute: #{$module-attribute}cookiedirective;
$module-message-box-attribute: #{$module-attribute}messagebox;
$module-to-top-attribute: #{$module-attribute}totop;
$module-password-checker-attribute: #{$module-attribute}passwordchecker;
$module-password-toggle-attribute: #{$module-attribute}passwordtoggle;
$module-expand-box-attribute: #{$module-attribute}expandbox;

$primary-background-color: gray;
$primary-font-color: gray;