#ez_debug_button {
	position: fixed;
	bottom: 0%;
	left: 0%;
	width: 90px;
	height: 50px;
	margin: auto;
	z-index: 10000;
	color: #fff;
	text-transform: uppercase;
	background: #022245;
	border-top-right-radius: 20px;
}
#ez_debug_container {
	&[data-ez-open=true] {
		position: fixed;
		width: 100vw;
		height: 100vh;
		z-index: 9999;
		top: 0;
		background: #002e4dd1;
		animation: appear 1s;
	}
	#ez_debug_content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 40vw;
		min-height: 40vh;
		max-width: 90vw;
		max-height: 70vh;
		overflow: auto;
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		background-color: #022245;
		border-radius: 6px;
		padding: 1rem;
		box-shadow: 0px 0px 8px 4px #fff;
		animation: appear_content 1s;
		>div {
			width: 100%;
			height: fit-content;
			text-align: center;
			color: #fff;
			word-break: break-all;
			white-space: break-spaces;
		}
	}
}
@keyframes appear {
	from {background: transparent }
	to { background: #002e4dd1; }
}
@keyframes appear_content {
	from { top: 100%;}
	to { top: 50%; }
}