@keyframes slide-down {
	from { transform: translateY( -100% ); }
	to { transform: translateY( 0% ); }
}

@keyframes slide-up {
	from { transform: translateY( 0% ); }
	to { transform: translateY( -100% ); display: none; }
}

@keyframes fade-in {
	from { opacity: 0; visibility: hidden; }
	to { opacity: 0.6; visibility: visible; }
}

@keyframes fade-out {
	from { opacity: 0.6; visibility: visible; }
	to { opacity: 0; visibility: hidden; }
}

@keyframes cookie-directive-close-options {
	0% { max-height: 282px; }
	100% { max-height: 0px; }
}