.ez-modules{

	//****************************** GRID SYSTEM ******************************//
	.row {
		display: block;
		margin-left: auto;
		margin-right: auto;

		.column,
		.columns{
			width: 100%;
			float: left;
		}

		.small-1{
			width: 8.33333333%;
		}
		.small-2{
			width: 16.66666666%;
		}
		.small-3{
			width: 25%;
		}
		.small-4{
			width: 33.33333333%;
		}
		.small-5{
			width: 41.66666666%;
		}
		.small-6{
			width: 50%;
		}
		.small-7{
			width: 58.33333333%;
		}
		.small-8{
			width: 66.66666666%;
		}
		.small-9{
			width: 75%;
		}
		.small-10{
			width: 83.33333333%;
		}
		.small-11{
			width: 91.66666666%;
		}
		.small-12{
			width: 100%;
		}
	}

	.row:before,
	.row:after{
		display: table;
		content: " ";
	}

	.row:after{
		clear: both;
	}

	//****************************** BASIC STYLES ******************************//
	.text-center{
		text-align: center
	}

	.text-left{
		text-align: left
	}

	.text-right{
		text-align: right
	}

}.ez-modules{

	//****************************** GRID SYSTEM ******************************//
	.row {
		display: block;
		margin-left: auto;
		margin-right: auto;

		.column,
		.columns{
			width: 100%;
			float: left;
		}

		.small-1{
			width: 8.33333333%;
		}
		.small-2{
			width: 16.66666666%;
		}
		.small-3{
			width: 25%;
		}
		.small-4{
			width: 33.33333333%;
		}
		.small-5{
			width: 41.66666666%;
		}
		.small-6{
			width: 50%;
		}
		.small-7{
			width: 58.33333333%;
		}
		.small-8{
			width: 66.66666666%;
		}
		.small-9{
			width: 75%;
		}
		.small-10{
			width: 83.33333333%;
		}
		.small-11{
			width: 91.66666666%;
		}
		.small-12{
			width: 100%;
		}
	}

	.row:before,
	.row:after{
		display: table;
		content: " ";
	}

	.row:after{
		clear: both;
	}

	//****************************** BASIC STYLES ******************************//
	.text-center{
		text-align: center
	}

	.text-left{
		text-align: left
	}

	.text-right{
		text-align: right
	}

}