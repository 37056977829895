.scroll_bar {
	height: 2px;
	width: 100%;
	background: transparent;
	position: fixed;
	top: 0;
	z-index: 9999;
	#scroll_indicator {
		height: 100%;
		width: 0%;
		background: red;
	}
}

#to_top {
	transition: 0.5s ease-in-out;
	position: fixed;
    right: 10px;
	bottom: 4px;
    z-index: 99999;
	opacity: 0;
	visibility: hidden;
	height: 48px;
	width: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
        max-width: 90px;
        max-height: 60px;
	}
    i {
		font-size: 24px;
		padding: 10px;
        color: #fff;
	}
	img:hover, i:hover {
		cursor: pointer;
	}
}