.tab_public_element{
	position: relative;
/*
	margin-top: 5px;
	padding: 0px 1.4em;
	border: 1px solid #bcbbbb;
	border-radius: 4px;
*/
	.headline{
/*
		padding: 5px 0px;
*/
		span{
			position: relative;
			display: inline-block;
			width: 100%;
/*
			color: $primary-background-color;
			font-size: 18px;
*/
		}

		span:hover,
		span .icon_plus:hover,
		span .icon_minus:hover {
			cursor: pointer;
		}

		span .icon_plus,
		span .icon_minus {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 0px;
			right: 0px;
		}

		span .icon_plus svg,
		span .icon_minus svg{
			max-height: 30px;
			max-width: 30px;
		}

		span[is-open="true"]{
			.icon_plus { display: none; }
			.icon_minus { display: initial; }
		}

		span[is-open="false"]{
			.icon_plus { display: initial; }
			.icon_minus { display: none; }
		}
	}	

	.content{
		border-top: 1px solid lightgray;
		padding-top: 5px;
	}
}

.svg_icon{
	fill: $primary-background-color;
}
.svg_icon_background{
	fill: white;
}